import React from "react";
import { Divider } from "antd";
import './Disclosures.scss'

function Terms() {
  return (
    <div className={'terms'}>
      <h1>Disclosures</h1>

      <div className="rodller-entry-content container">

        <div className="wp-block-separator has-text-color has-css-opacity has-background is-style-wide">
          <div >
            <strong>Important Notice</strong>: MetaVerse Green Exchange Pte. Ltd. ("<strong>MVGX</strong>"), a Singapore private limited company (Company Registration No. 201825931K). MVGX holds a Capital Markets Services license No. 101073 ("<strong>CMSL</strong>") to deal in securities by the Monetary Authority of Singapore (the "<strong>Authority</strong>"), which allows MVGX to facilitate the primary offer and issuance of securities and act as an intermediary between issuers and investors.
            MVGX is also licensed by the Authority as a recognised market operator ("<strong>RMO</strong>"), to operate an organised market in respect of securities and units in collective investment schemes ("<strong>CIS</strong>"), the property of which consists only of capital markets products. (the "<strong>Organised Market</strong>")
          </div>


          <p style={{padding: '15px 0'}}><strong>Disclosure is hereby made to all participants that:</strong></p>

          <div>
            i) the admission of issuers of units in CIS made available for trading on the Organised Market are not subject to the Authority's oversight; and <br></br>

            ii) all offers of units in CIS made on the Organised Market are made in reliance on an exemption under Subdivision (4) of Division 2 under Part 13 of the Securities & Futures Act 2001 ("<strong>SFA</strong>"); and<br></br>

            iii) the admission of issuers of units in CIS made available for trading on the Exempted Market are not subject to the Authority's oversight; and<br></br>

            iv) all offers of units in CIS made on the Exempted Market are made in reliance on an exemption under Subdivision (4) of Division 2 under Part 13 of the SFA.<br></br>

            In addition, to avoid any misunderstanding over the regulatory status of the RMO's activities:<br></br>

            i) that the <strong>RMO's recognition as an RMO</strong> under section 9(1)(b) of the SFA is restricted to its <strong>operations of the Organised Market</strong>; and<br></br>

            ii) the <strong>list of products available for trading on the Organised Market that are not regulated under the SFA</strong>, if there are any such products.<br></br>
          </div>
          <Divider dashed style={{'margin': '0', 'marginTop': "22px", borderColor: '#999'}} />

          <p><strong><u>Important Information on Specific Classes of Investors</u></strong></p>

          <div>
           MetaVerse Green Exchange Pte. Ltd. ("MVGX") is required to ensure that it only engages with prescribed customers ("Applicant") under its license conditions such as those classified and defined in the Securities and Futures Act (Cap.289) (the "SFA"). For more information about the specific classes of investors, please click <a
           href="https://sso.agc.gov.sg/Act/SFA2001?ProvIds=pr4A-#pr4A-" target={'blank'}><u>here</u></a>.<br></br>
           <div style={{padding: '8px 0'}}></div>
           The classification of the customers' status according to specific classes of investors is based on the Applicant's declaration and supporting documents provided.
          </div>

          <p><strong><u>Classes of Investors</u></strong></p>

          <div>
            <div style={{marginBottom: '12px'}}><strong>(1) Accredited Investor</strong></div>

            <u>Individuals</u>
            <br></br>
            i. Net personal assets exceed S$2,000,000 (or its equivalent in a foreign currency) in value, of which no more than S$1,000,000 (or its equivalent in a foreign currency) in value is contributed by the net estimated fair market value of his/her primary residence.<br></br>

            ii. Financial assets (net of any related liabilities) exceed S$1,000,000 in value (or its equivalent in a foreign currency).<br></br>

            iii. Income in the preceding twelve (12) months is not less than S$300,000 (or its equivalent in a foreign currency).<br></br>
            <div style={{padding: '6px 0'}}></div>
            <u>Corporate</u>
            <br></br>
            i. Net assets exceed S$10,000,000 (or its equivalent in a foreign currency) as determined by (a) its most recent audited balance-sheet or (b) where the Applicant is not required to prepare audited accounts regularly, its balance-sheet certified by the Applicant as giving a true and fair view of its state of affairs as of the date of the balance-sheet (which date is within the twelve (12) months preceding the date of submitting the Account Opening Form).<br></br>

            ii. Entire share capital is owned by one or more persons, all of whom are accredited investors as defined in section 4A of the Securities and Futures Act.<br></br>

            Refer to below on <strong>Important Information on Accredited Investor (AI) Regime</strong> for more information on being treated as an AI.<br></br>

            <div style={{margin: '15px 0'}}><strong>(2) Expert Investor</strong></div>

            i. A person whose business involves the acquisition and disposal, or the holding, of capital markets products, whether as principal or agent;<br></br>

            ii. The trustee of such trust as the MAS may prescribe, when acting in that capacity; or<br></br>

            iii. Such other person as the MAS may prescribe.<br></br>

            <div style={{margin: '15px 0'}}><strong>(3) Professional Investor</strong></div>

            (a) a bank that is licensed under the Banking Act (Cap. 19);<br></br>
            (b) a merchant bank that is licensed, or treated as having been granted a
            merchant bank licence, under the Banking Act;<br></br>
            (c) a finance company that is licensed under the Finance Companies Act
            (Cap. 108);<br></br>
            (d) an authorised reinsurer as defined in section 1A of the Insurance Act
            (Cap. 142), a licensed insurer licensed under section 8 of the Insurance
            Act, or a foreign insurer as defined in section 1A of the Insurance Act
            carrying on insurance business in Singapore under any foreign insurance
            scheme established in accordance with section 35B of the Insurance Act;<br></br>
            (e) the Singapore Government;<br></br>
            (f) a statutory body established under any Act in Singapore;<br></br>
            (g) the Government of Singapore Investment Corporation Pte Ltd;<br></br>

            (h) a pension fund;<br></br>
            (i) a collective investment scheme, as defined under section 2(1) of the SFA;<br></br>
            (j) a holder of a capital markets services licence under the SFA;<br></br>
            (k) a person who is exempted from the requirement to hold a capital
            markets services licence to carry on business in dealing in capital markets
            products that are futures contracts or over-the-counter derivatives
            contracts under the Second Schedule to the Securities and Futures
            (Licensing and Conduct of Business) Regulations;<br></br>
            (l) a person who is exempted from the requirement to hold a capital
            markets services licence to carry on business in fund management in the
            Second Schedule to the SF (LCB) Regulations, and who has assets under
            its management of not less than S$15 million;<br></br>
            (m) headquarters company or Finance and Treasury Centre which carries on
            a class of business involving fund management but only to the extent that
            the business in fund management has been approved as a qualifying
            service in relation to that headquarters company or Finance and Treasury
            Centre under section 43E(2)(a) or 43G(2)(a) of the Income Tax Act (Cap.
            134), as the case may be;<br></br>
            (n) a company in the Global Trader Programme of Enterprise Singapore;<br></br>
            (o) a financial adviser licensed under the Financial Advisers Act (Cap. 110)
            who uses CTX's services solely for the purposes of trading for its own
            account; or<br></br>
            (p) a hedge fund that has assets under management of not less than S$15
            million.<br></br>

            <div style={{margin: '15px 0'}}><strong>(4) Institutional Investo</strong>r</div>

            i. The Government of Singapore;<br></br>

            ii. A statutory board as may be prescribed by regulations made under Section 341 of the Securities and Futures Act;<br></br>

            iii. An entity that is wholly and beneficially owned, whether directly or indirectly, by a central government of a country and whose principal activity is <br></br>

            (A) To manage its own funds;<br></br>

            (B) To manage the funds of the central government of that country (which may include the reserves of that central government and any pension or provident fund of that country); or<br></br>

            (C) To manage the funds (which may include the reserves of that central government and any pension or provident fund of that country) of another entity that is wholly and beneficially owned, whether directly or indirectly, by the central government of that country;<br></br>

            iv. Any entity — <br></br>

            (A) That is wholly and beneficially owned, whether directly or indirectly, by the central government of a country; and<br></br>

            (B) Whose funds are managed by an entity that is wholly and beneficially owned, whether directly or indirectly, by a central government of a country and whose principal activity is <br></br>

            <div style={{paddingLeft: '15px'}}>
              1.To manage its own funds;<br></br>

              2.To manage the funds of the central government of that country (which may include the reserves of that central government and any pension or provident fund of that country); or<br></br>

              3.To manage the funds (which may include the reserves of that central government and any pension or provident fund of that country) of another entity that is wholly and beneficially owned, whether directly or indirectly, by the central government of that country;
            </div>

            v. A central bank in a jurisdiction other than Singapore;<br></br>

            vi. A central government in a country other than Singapore;<br></br>

            vii. An agency (of a central government in a country other than Singapore) that is incorporated or established in a country other than Singapore;<br></br>

            viii. A multilateral agency, international organisation or supranational agency as may be prescribed by regulations made under Section 341;<br></br>

            ix. A bank that is licensed under the Banking Act (Cap. 19);<br></br>

            x. A merchant bank that is approved as a financial institution under Section 28 of the Monetary Authority of Singapore Act (Cap. 186);<br></br>

            xi. A finance company that is licensed under the Finance Companies Act (Cap. 108);<br></br>

            xii. A company or co-operative society that is licensed under the Insurance Act (Cap. 142) to carry on insurance business in Singapore;<br></br>

            xiii. A company licensed under the Trust Companies Act (Cap. 336);<br></br>

            xiv. A holder of a capital markets services license;<br></br>

            xvi. An approved exchange;<br></br>

            xvii. A recognized market operator;<br></br>

            xviii. An approved clearing house;<br></br>

            xix. A recognized clearing house;<br></br>

            xx. A licensed trade repository;<br></br>

            xxi. A licensed foreign trade repository;<br></br>

            xxii. An approved holding company;<br></br>

            xxiii. A depository as defined in Section 81SF of the Securities and Futures Act;<br></br>

            xxiv. An entity or a trust formed or incorporated in a jurisdiction other than Singapore, which is regulated for the carrying on of any financial activity in that jurisdiction by a public authority of that jurisdiction that exercises a function that corresponds to a regulatory function of the Monetary Authority of Singapore under this Act, the Banking Act (Cap. 19), the Finance Companies Act (Cap. 108), the Monetary Authority of Singapore Act (Cap. 186), the Insurance Act (Cap. 142), the Trust Companies Act (Cap. 336) or such other Act as may be prescribed by regulations made under Section 341 of the Securities and Futures Act;<br></br>

            xxv. A pension fund, or collective investment scheme, whether constituted in Singapore or elsewhere;<br></br>

            xxvi. A person (other than an individual) who carries on the business of dealing in bonds with accredited investors or expert investors;<br></br>

            xxvii. The trustee of such trust as the Monetary Authority of Singapore may prescribe, when acting in that capacity; or<br></br>

            xxviii. Such other person as the Monetary Authority of Singapore may prescribe.<br></br>
          </div>
          
          <p><strong><u>Important Information on Accredited Investor (AI) Regime</u></strong></p>
          <div>
            <div style={{margin: '15px 0'}}><strong>1.For Your Information</strong></div>
            MVGX is required under Singapore law to provide you with certain information before you make a decision on whether you wish to continue to be treated as an accredited investor as defined in section 4A of the Securities and Futures Act (Cap. 289) (the “SFA”). Accordingly, we hereby inform you that: <br></br>

            a.  we have assessed you to be an “accredited investor” as defined in section 4A of the SFA based on the information you have provided to us; <br></br>

            b.  you may, but are not obliged to, consent to being treated by us as an accredited investor for the purposes of the statutory provisions set out in regulation 3(9) of the Securities and Futures (Classes of Investors) Regulations 2018; and<br></br>

            c.  if you consent to the treatment outlined in paragraph (b) above, you may withdraw your consent at any time, upon which we will cease to treat you as an accredited investor after we have confirmed to you in writing your removal of accredited investor status. <br></br>
            <div style={{height: '15px'}}></div>
            <strong>General Warning:</strong> Accredited investors are assumed to be better informed, and better able to access resources to protect their own interests, and therefore require less regulatory protection. Investors who agree to be treated as accredited investors therefore forgo the benefit of certain regulatory safeguards. For example, issuers of securities are exempted from issuing a full prospectus registered with the Monetary Authority of Singapore in respect of offers that are made only to accredited investors, and intermediaries are exempted from a number of business conduct requirements when dealing with accredited investors. Investors should consult a professional adviser if they do not understand any consequence of being treated as an accredited investor.<br></br>
            <div style={{margin: '15px 0'}}><strong>2 .Regulatory Safeguards that do not extend to Accredited Investors</strong></div>
            Where we as a Capital Market Services Licensee (“CMSL”) deal with you as an Accredited Investor (AI), we would be exempt from complying with certain requirements under the Securities and Futures Act, Chapter 289 of Singapore (“SFA”) and certain regulations and notices issued there under. Please note that the regulatory requirements that we are exempted from when dealing with you as an Accredited Investor may be amended and updated from time to time due to regulatory changes or otherwise. We have summarised the requirements below.

            <div className={'regime'} >
              <div className={'items'}>
                <p>
                  <strong>Retail Investor</strong>
                </p>
                <p>
                  <strong>Accredited Investor</strong>
                </p>
              </div>
              <p className={'item'}><span>Prospectus Exemptions under Sections 275 and 305 of the SFA</span></p>
              <div className={'items'}>
                <p>
                  <span>The issuer/offeror is exempted from registering a prospectus when the offer of securities and securities-based derivatives contracts, and units of collective investment schemes is made to relevant persons. </span>
                  <span>Secondary sale restriction and prohibition from being a transferee within 6 months after securities, securities-based derivative contracts, units of CIS are acquired by the Corporation or interests in the Trust.</span>
                </p>
                <p>
                  <span>The issuer/offeror is exempted from registering a prospectus when the offer of securities and securities-based derivatives contracts, and units of collective investment schemes is made to relevant persons. Relevant persons include Accredited Investors.</span>
                  <span>No restriction on secondary sale and prohibition from being a transferee of the Corporation or interests in the Trust.</span>
                </p>
              </div>
              <p className={'item'}><span>Restrictions on  Advertisements under Sections 251 and 300 of the SFA</span></p>
              <div className={'items'}>
                <p>
                  <span>Prohibit any advertisement or publication referring to an offer or intended offer of securities and securities-based derivatives contracts, and units of collective investment schemes from being made.</span>
                </p>
                <p>
                  <span>Dissemination of, and presentation of oral or written material on matters contained in, the preliminary document (prior to prospectus registration) which has been lodged with the MAS to institutional investors and relevant persons. Relevant persons include Accredited Investors.</span>
                </p>
              </div>
              <p className={'item'}><span>Customer’s  Moneys under Part  III, Division 2 of SFA (Licensing and Conduct of Business)</span></p>
              <div className={'items'}>
                <p>
                  <span>The CMSL to make certain disclosures (such as whether the moneys will be commingled with other customers and the risks of commingling, consequences if the CMSL which maintains the trust account becomes insolvent) in writing prior to depositing moneys in trust account.</span>
                </p>
                <p>
                  <span>No such requirement.</span>
                </p>
              </div>
              <div className={'items'}>
                <p>
                  <span>The CSML is not permitted to transfer retail  investor’s moneys from trust account, to meet any obligation of the CMSL in relation to any transaction entered into by CMSL for the benefit of the CMSL.</span>
                </p>
                <p>
                  <span>No such prohibition.</span>
                </p>
              </div>
              <div className={'items'}>
                <p>
                  <span>Deposit moneys received on account of its customers into a trust account maintained in accordance with Regulation 17 of the SFA with specified financial institutions or;</span>
                  <span>Deposit into account directed by a retail customer to which the retail customer has legal and beneficial title and maintained with, inter alia, licensed banks, merchant banks or finance companies or banks established and regulated as banks outside Singapore.</span>
                </p>
                <p>
                  <span>Deposit moneys received on account of its customers into a trust account maintained in accordance with Regulation 17 of the SFA with specified financial institutions or;</span>
                  <span>Deposit into account directed by an Accredited Investor.</span>
                </p>
              </div>
              <p className={'item'}><span>Provision of Account Statement under s(40) Part IV of SFA (Licensing and Conduct of Business)</span></p>
              <div className={'items'}>
                <p>
                  <span>CMSL is required to furnish to each customer a statement of account monthly/quarterly, containing certain particulars and, where applicable, the assets, derivatives contracts of the customer that are outstanding and have not been liquidated and cash balances (if any) of the customer at the end of that quarter.</span>
                </p>
                <p>
                  <span>No such requirements, and provided the CMSL has made available (on a real-time basis) the prescribed particulars of the account  in the form of electronic records stored on an electronic facility, or have requested CMSL in writing not to receive the statement of account.</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Terms;